/* Global Styles */
body {
  background-color: #ffffff;
}
.main-content {
    padding-top: 80px; /* Adjust this value based on your navbar height */
  }

/* Navbar Styles */
.navbar.navbar-custom {
  background-color: #3E50B4 !important;
  border-top: solid 8px #2F3E9E;
  box-shadow: 0 2px 10px #e4e4e4;
}

.navbar-brand img {
  height: 38px;
}

.navbar-nav > li > a.nav-link {
  color: #ffffff !important;
  opacity: 0.6;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1.2px;
}

.navbar-nav > li > a.nav-link:hover,
.navbar-nav > li > a.nav-link:focus,
.navbar-nav > .active > a.nav-link {
  color: #ffffff !important;
  opacity: 1;
  background: #3E50B4;
}

.navbar-toggle .icon-bar {
  background-color: #ffffff;
}

/* Drug Search Styles */
.drug-search-container {
  max-width: 800px;
  margin: 0 auto;
}

.search-card {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

#drug_balancer_logo {
  text-align: center;
  padding: 30px 0;
}

#custom-search-input {
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(47, 62, 158, 0.24);
  border: solid 1px #eeeeee;
}

#custom-search-input:focus,
#custom-search-input:hover {
  box-shadow: 0 2px 4px 0 rgba(47, 62, 158, 0.24);
  border: solid 1px #bcbec0;
}

#span_btn_search {
  border-radius: 1px;
  background-color: #FEC006;
  box-shadow: 0 0 0 1px rgba(47, 62, 158, 0.24);
}

#span_btn_search:hover {
  background-color: #f2b705;
}

#span_btn_search .glyphicon-search {
  font-size: 20px;
  color: #ffffff;
  top: 2px;
}

#span_btn_clear .glyphicon-remove-circle {
  font-size: 14px;
  font-weight: 100;
  opacity: 0.7;
  color: #747474;
}

.search-mode-tabs .nav-link {
  display: flex;
  align-items: center;
}

.search-mode-tabs .nav-link img {
  margin-right: 5px;
}

/* Updated styles for mobile devices */
@media (max-width: 576px) {
  .search-mode-tabs {
    flex-direction: row;
    justify-content: center;
  }

  .search-mode-tabs .nav-item {
    flex: 0 1 auto;
  }

  .search-mode-tabs .nav-link {
    flex-direction: row;
    text-align: center;
    padding: 0.5rem 0.75rem;
    font-size: 0.9rem;
  }

  .search-mode-tabs .nav-link img {
    margin-right: 5px;
    margin-bottom: 0;
    width: 20px;
    height: 20px;
  }
}

#custom-search-input {
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(47, 62, 158, 0.24);
  border: solid 1px #eeeeee;
}

#custom-search-input:focus,
#custom-search-input:hover {
  box-shadow: 0 2px 4px 0 rgba(47, 62, 158, 0.24);
  border: solid 1px #bcbec0;
}

.input-group-btn button {
  border-radius: 1px;
  background-color: #FEC006;
  box-shadow: 0 0 0 1px rgba(47, 62, 158, 0.24);
}

.input-group-btn button:hover {
  background-color: #f2b705;
}

.search-tips {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
}

.search-tips h5 {
  color: #495057;
}

.search-tips ul {
  padding-left: 20px;
}

.drug-detail-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.drug-icons {
  display: flex;
  gap: 10px;
}

.drug-icons > div {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Button Styles */
.btn-primary {
  background-color:  #3E50B4 !important;
  border-color:  #3E50B4;
  color:  #ffffff !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background-color: #3E50B4 !important;
  border-color: #3E50B4 !important;
  color: #FEC006 !important ;
}

.btn-secondary {
  background-color: #3E50B4;
  border-color: #3E50B4;
  color: #ffffff;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
  background-color: #FEC006 !important;
  border-color: #FEC006 !important;
  color: #3E50B4 !important;
}

/* Footer Styles */
.footer {
  background-color: #eaecf7;
  border-top: solid 8px #e5e7f0;
  padding: 10px 0;
}

.footer .text-muted {
  color: #747474;
  font-size: 15px;
  letter-spacing: 0.1px;
}

/* Responsive Styles */
@media (max-width: 767px) {
  .navbar-brand {
    padding: 5px 30px 7px;
  }
  
  .navbar-medkit.pull-right {
    margin-top: -35px;
  }
  
  .signin.pull-right {
    margin-top: -36px;
  }
}

@media (min-width: 768px) {
  .navbar-medkit.pull-right {
    margin-top: 12px;
  }
  
  .signin.pull-right {
    margin-top: 16px;
  }
}

@media (min-width: 992px) {
  .landing-content {
    padding: 60px;
  }
}

/* Drug Details Styles */
.drug-header {
  margin: 40px 0 0;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.75px;
  color: #2C3A8D;
}

.panel-title {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: #2f3e9e;
  padding-top: 10px;
}

.panel-inner-header {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #060143;
  margin: 15px 0 10px;
}

.reg-text {
  letter-spacing: 0.3px;
  font-size: 16px;
}

.label-custom {
  color: #2f3e9e;
  background-color: transparent;
}

.btn-icon {
  outline: none;
  border: 0;
  color: #2C3A8D;
  background: transparent;
  vertical-align: middle;
}

.well-sm {
  background: #FFFFFD;
  font-size: 14px;
}

i.fa-info-circle {
  color: #FEC006;
}

.field-link {
  color: #3B5EBD;
}

.field-link:focus,
.field-link:hover {
  color: #3B5EBD;
}

.result-card-header {
    background-color: #3E50B4 !important;
  }

.search-results .list-group {
  max-height: 350px;
  overflow-y: auto;
}

.search-results .list-group-item {
  border-left: none;
  border-right: none;
}

.search-results .suggest-list-name {
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: capitalize;
}

.search-results .list-group-item-action:hover {
  background-color: #f8f9fa;
}

.search-results mark {
  background-color: #ffeeba;
  padding: 0.1em 0.2em;
  border-radius: 2px;
}

.App .btn-primary,
.App .btn-secondary {
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}
.highlight p {
  background-color: #fff3cd;
  padding: 5px;
  border-radius: 3px;
  transition: all 0.3s ease;
  box-shadow: 0 0 5px rgba(254, 192, 6, 0.5);
}

[dir="ltr"] .highlight p {
  border-left: 3px solid #FEC006;
}

[dir="rtl"] .highlight p {
  border-right: 3px solid #FEC006;
}

.highlight:hover p {
  background-color: #ffeeba;
  box-shadow: 0 0 8px rgba(254, 192, 6, 0.7);
}

.section-total {
    background-color: #f8f9fa;
    border: 1px solid #e9ecef;
    border-radius: 20px;
    padding: 2px 10px;
    font-size: 0.9rem;
    font-weight: bold;
    color: #495057;
  }
  .footer {
    padding: 2rem 0;
  }
  
  .footer ul {
    margin-bottom: 0;
  }
  
  .footer li {
    margin-bottom: 0.5rem;
  }
  
  .footer .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .footer .col-auto {
    padding: 0 1rem;
  }
  
  @media (max-width: 767.98px) {
    .footer .col-auto {
      flex-basis: 50%;
      margin-bottom: 1.5rem;
    }
  }

  /* Link styles */
a {
  color: #3E50B4 !important;
  transition: color 0.3s ease;
}

a:hover,
a:focus {
  color: #FEC006 !important;
  text-decoration: none;
}

/* Checkbox styles */
/* For default checkboxes */
input[type="checkbox"]:checked {
  background-color: #3E50B4 !important;
  border-color: #3E50B4 !important;
}

/* For Bootstrap's custom checkboxes */
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #3E50B4 !important;
  background-color: #3E50B4 !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e") !important;
}

/* Focus styles for accessibility */
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(62, 80, 180, 0.25) !important;
}

/* Style for focus state of links */
a:focus {
  outline: 2px solid rgba(62, 80, 180, 0.5) !important;
  outline-offset: 2px;
}

/* Pagination Styles */
.pagination .page-item .page-link {
  color: #3E50B4;
  background-color: #ffffff;
  border-color: #3E50B4;
  transition: all 0.3s ease;
}

.pagination .page-item .page-link:hover {
  color: #ffffff;
  background-color: #3E50B4;
  border-color: #3E50B4;
}

.pagination .page-item.active .page-link {
  color: #ffffff;
  background-color: #3E50B4;
  border-color: #3E50B4;
}

.pagination .page-item.disabled .page-link {
  color: #6c757d;
  background-color: #ffffff;
  border-color: #dee2e6;
}

/* Add this to your main CSS file */
.reference-item {
  scroll-margin-top: 100px; /* Adjust this value based on your navbar height */
}

.contact-container {
    text-align: center;
    padding: 20px;
  }
  
  .contact-details {
    margin-bottom: 20px;
  }
  
  .contact-images img {
    max-width: 100%;
    height: auto;
    margin: 10px;
  }

/* Navbar Alert Styles */
.navbar-custom .alert {
  margin-bottom: 0;
  border-radius: 4px;
}

.navbar-custom .alert small {
  color: inherit;
}

.navbar-custom .alert .btn-link {
  color: inherit;
  text-decoration: underline;
  padding: 0;
  font-size: inherit;
}

.navbar-custom .alert .btn-link:hover {
  text-decoration: none;
}

.modal-content {
  background-color: #000;
}

.modal-header {
  border-bottom: none;
  padding: 1rem;
}

.modal-header .btn-close {
  color: #fff;
  filter: invert(1) grayscale(100%) brightness(200%);
}

.modal-title {
  color: #fff;
}